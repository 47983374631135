.section-one {
    min-height: 70vh;
    background-color: #7B506F;
    align-items: center;
    flex-direction: column;
}

.main-text {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
    font-size: 2rem;
}