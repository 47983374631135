.section-footer {
    min-height: 30vh;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #1F1A38;
}

.footer-container {
    padding: 4rem;
    margin: 0px auto;
}

.linkedin {
    text-decoration: none;
    img {
        height: 100px;
        width: 100px;
    }
}

a {
    color: white;
}